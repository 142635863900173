@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 51;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #FCFCFC;

  @media (--viewportMedium) {
    max-width: calc(100% - 426px);
  }

  @media (--viewportLarge) {
    max-width: calc(100% - 446px);
  }

  @media (--viewportMLarge) {
    /* flex-basis: 58.44%; */
    flex-basis: 62%;
    max-width: none;
    padding-right: 40px;
  }

  @media (--viewportLLarge) {
    flex-basis: 58.44%;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  
  @media (--viewportLarge) {
    padding-left: 36px;
  }

  @media (--viewportMLarge) {
    padding-left: 50px;
  }
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;
  background-color: #FCFCFC;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 28px;
  }

  @media (--viewportLarge) {
    padding: 0 0 0 36px;
  }

  @media (--viewportMLarge) {
    padding: 0 0 0 50px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.advancedFilters {
  /* display: none; */

  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: calc(100% - 48px);
  padding: 14px;
  background: var(--marketplaceColorLight);
  border-radius: 20px;
  
  @media (--viewportMedium) {
    margin: 0 0 26px auto;
    max-width: calc(100% - 24px);
  }
  
  @media (--viewportLarge) {
    margin: 0 auto 26px;
    max-width: calc(100% - 72px);
  }

  @media (--viewportMLarge) {
    flex-direction: row;
    max-width: calc(100% - 100px);
    max-width: 670px;
    margin: 0 0 26px 50px;
    padding: 13px 0 14px;
    border-radius: 42px;
  }

  @media (--viewportLLarge) {
    max-width: calc(100% - 48px);
    max-width: 883px;
  }

  & > div {
    display: block;
    width: 100%;

    @media (--viewportMLarge) {
      display: inline-block;
      width: auto;
    }
  }
}

.advancedFiltersLocation {
  width: 100%;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusWrapper);
  margin: 0 0 14px 0;

  @media (--viewportMLarge) {
    margin: 0 0 0 14px;
    width: auto;
  }

  & input {
    width: 100%;
    padding: 0 30px;
    height: auto;
    min-height: 36px;
    border-width: 1px;
    background: url("data:image/svg+xml,%3Csvg width='11' height='13' viewBox='0 0 11 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.11962 11.0197L7.93541 8.20392C8.49225 7.64702 8.87145 6.93751 9.02506 6.1651C9.17867 5.39269 9.09979 4.59208 8.7984 3.8645C8.497 3.13693 7.98663 2.51506 7.33182 2.07754C6.677 1.64002 5.90715 1.40649 5.11962 1.40649C4.33209 1.40649 3.56224 1.64002 2.90742 2.07754C2.25261 2.51506 1.74223 3.13693 1.44084 3.8645C1.13945 4.59208 1.06057 5.39269 1.21418 6.1651C1.36779 6.93751 1.74699 7.64702 2.30383 8.20392L5.11962 11.0197ZM5.11962 12.6284L1.49948 9.00827C0.783496 8.29227 0.295906 7.38004 0.0983697 6.38694C-0.0991668 5.39383 0.0022224 4.36445 0.389716 3.42896C0.777209 2.49348 1.4334 1.69391 2.27532 1.13136C3.11723 0.568813 4.10706 0.268555 5.11962 0.268555C6.13218 0.268555 7.122 0.568813 7.96392 1.13136C8.80583 1.69391 9.46203 2.49348 9.84952 3.42896C10.237 4.36445 10.3384 5.39383 10.1409 6.38694C9.94333 7.38004 9.45574 8.29227 8.73976 9.00827L5.11962 12.6284V12.6284ZM5.11962 6.52582C5.42135 6.52582 5.71073 6.40596 5.92409 6.1926C6.13745 5.97924 6.25731 5.68986 6.25731 5.38813C6.25731 5.08639 6.13745 4.79702 5.92409 4.58366C5.71073 4.3703 5.42135 4.25043 5.11962 4.25043C4.81788 4.25043 4.52851 4.3703 4.31515 4.58366C4.10179 4.79702 3.98193 5.08639 3.98193 5.38813C3.98193 5.68986 4.10179 5.97924 4.31515 6.1926C4.52851 6.40596 4.81788 6.52582 5.11962 6.52582ZM5.11962 7.66351C4.51615 7.66351 3.9374 7.42379 3.51068 6.99707C3.08396 6.57035 2.84423 5.9916 2.84423 5.38813C2.84423 4.78466 3.08396 4.2059 3.51068 3.77919C3.9374 3.35247 4.51615 3.11274 5.11962 3.11274C5.72309 3.11274 6.30184 3.35247 6.72856 3.77919C7.15528 4.2059 7.395 4.78466 7.395 5.38813C7.395 5.9916 7.15528 6.57035 6.72856 6.99707C6.30184 7.42379 5.72309 7.66351 5.11962 7.66351Z' fill='%232F4E1E'/%3E%3C/svg%3E%0A") no-repeat;
    background-position: 15px center;
    font-size: 12px;

    @media (--viewportMLarge) {
      max-width: 240px;
    }
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 50px 50px 50px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 41.56%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  /* @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px; */

  @apply --marketplaceSearchFilterButton;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-size: 12px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;
    z-index: 1;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    max-width: 426px;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportMLarge) {
    width: 40vw;
    /* width: 41.56vw; */
    max-width: none;
  }

  @media (--viewportLLarge) {
    width: 41.56vw;
  }
}
